<template>


  <div class="guest__page">
    <div class="guest__page--header" >
<!--      <i class="mdi mdi-map-marker"></i>-->

<!--      <i class="mdi mdi-bell-ring"></i>-->
    </div>
    <div class="guest__page--info">
<!--      <div class="guest__page&#45;&#45;code">-->
<!--        <p>Количество клиентов</p>-->
<!--        <p>{{ }}</p>-->
<!--      </div>-->

    </div>

    <div class="">

    </div>



    <div class="guest__page--company-block">

      <div class="guest__page--top" >
        <p :class="{ 'title--active': show_clients === true }" @click="getClients(true)">Клиенты</p>
        <p :class="{ 'title--active': show_clients === false }" @click="getClients(false)">Тестовые пользователи</p>
      </div>


      <div class="guest__page--companies">
        <div
            class="guest__page--company"
            v-for="(client,index) in clients"
            :key="index"
        >
          <a @click="openClientModal(client)">{{ client.name }}</a>

          <div class="guest__page--photos">
            <div
                class="guest__page--photo"
                v-for="(photo,photo_index) in client.photos" :key="photo_index">
              <img  :src="$BACK_URL+'storage/'+photo.value" alt="">
              <a href="#">удалить</a>
            </div>
          </div>

          <h3>Телефоны: </h3>
          <div class="guest__page--phones">
            <div
                class="guest__page--photo"
                v-for="(phone,phone_index) in client.phones" :key="phone_index">
              <p class="mb-0">{{ phone.value }}</p>
              <a href="#">удалить</a>
            </div>
          </div>
          <h3>Пользователи: </h3>
          <div class="guest__page--clients">
            <p class="mt-2" v-for="(item,user_index) in client.company_users" :key="user_index">{{ user_index+1 }}) {{ item.user.email }}</p>
          </div>


          <h3>Количество клиентов: {{ client.bonuses_count }} </h3>

          <h3 v-if="client.last && client.last.last">Дата последнего добавленнего клиента: {{ client.last.last.created_at }} </h3>


          <p v-if="client.city"><b>Город: </b>{{ client.city.name }}</p>


          <p ><b>Дата подключение:</b> {{ client.created_at }}</p>

          <p v-if="checkDate(client.created_at)">Төлейтін күн</p>
<!--          <p v-else>Ерте</p>-->
        </div>
      </div>
    </div>


    <v-dialog max-width="600" v-model="open_client_modal">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Клиент</p>
          {{ this.email }}

          <v-text-field
              v-model="name"
              label="Название"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>


          <v-select
              :items="countries"
              label="Страна"
              v-model="country"
              item-text="name"
              item-value="id"
              outlined
              @change="handleCountryChange"
          ></v-select>
          <v-select
              :items="cities"
              label="Город"
              v-model="city"
              item-text="name"
              item-value="id"
              outlined
          ></v-select>

          <v-textarea
              v-model="description"
              label="Описание"
              required
              outlined
          ></v-textarea>

          <v-btn @click="openAddress" class="primary mb-4">Добавить адрес +</v-btn>
          <v-btn @click="openPhone" class="primary mb-4">Добавить телефон +</v-btn>
          <v-btn @click="openSocial" class="primary mb-4">Cоциальный сеть +</v-btn>
          <v-btn @click="openPhoto" class="primary mb-4">Добавить фотографию +</v-btn>
          <v-switch
              v-model="is_client"
              color="green"
              label="Клиент"
              hide-details
              class="mb-4"
          ></v-switch>
          <v-btn
              type="submit"
              class="save__btn item__pointer"
              @click="saveIsClient()"
              color="green"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="open_phone">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Телефон</p>

          <v-text-field
              v-model="name"
              label="Название"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>

          <v-text-field
              v-model="value"
              label="Значение"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>

          <v-btn
              type="submit"
              class="save__btn item__pointer"
              @click="savePhone()"
              color="green"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="open_address">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Адрес</p>

          <v-text-field
              v-model="name"
              label="Название"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>

          <v-text-field
              v-model="value"
              label="Значение"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>

          <v-btn
              type="submit"
              class="save__btn item__pointer"
              @click="saveAddress()"
              color="green"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="open_social">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Социальный сеть</p>

          <v-text-field
              v-model="name"
              label="Название"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>

          <v-text-field
              v-model="value"
              label="Значение"
              required
              outlined
              class="input"
              type="text"
          ></v-text-field>
          <v-btn
              type="submit"
              class="save__btn item__pointer"
              @click="saveSocial()"
              color="green"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>


    <v-dialog max-width="600" v-model="open_photo">
      <v-card>
        <div class="pa-5 item__column modal__bonus">
          <p class="modal__bonus__title">Фотография</p>

          <v-file-input
              v-model="photo"
              :rules="rules"
              accept="image/png, image/jpeg, image/bmp"
              label="Фотография"
              prepend-icon="mdi-camera"
          ></v-file-input>

          <v-btn
              type="submit"
              class="save__btn item__pointer"
              @click="savePhoto()"
              color="green"
          >
            Сохранить
          </v-btn>
        </div>
      </v-card>
    </v-dialog>



    <h2></h2>
  </div>
</template>


<script>
export default {
  name: "ClientPayment",
  data() {
    return {
      country: '',
      city: '',
      countries: [],
      cities: [],
      photo: '',
      clients: [],
      open_client_modal: false,
      is_client: false,
      email: '',
      show_clients: true,
      address: '',
      description: '',
      rules: [
        value => {
          return !value || !value.length || value[0].size < 2000000 || 'Avatar size should be less than 2 MB!'
        },
      ],
      open_address: false,
      open_photo: false,
      open_social: false,
      name: '',
      value: '',
      company_id: '',
      open_phone: false
    }
  },
  mounted() {
    this.getClients(true);
    this.getCountries()
  },
  methods: {
    handleCountryChange() {
      console.log("tththt",this.country)
      console.log('Selected country:', this.country);
      this.getCities()
    },
    getCountries() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}guest/countries`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {
            console.log("countries",res.data)
            this.countries = res.data

          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    getCities() {
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}guest/cities?country_id=${this.country}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        }
      })
          .then((res) => {

            console.log("cities",res.data)

            this.cities = res.data
          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    openPhone() {
      this.open_phone = true
    },
    saveAddress() {
      let data = {
        name: this.name,
        value: this.value,
        company_id: this.company_id
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}guest/company/address`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
            this.open_address = false
            this.open_client_modal = false
            this.name = ''
            this.value = ''

          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    savePhoto() {
      const formData = new FormData();
      formData.append('photo', this.photo)
      formData.append('company_id', this.company_id)
      this.$axios({
        method: 'post',
        url: `${this.$API_URL}guest/company/photo`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          'Content-Type': 'multipart/form-data', // Add this line for proper handling of form data
        },
        data: formData,
      })
          .then(response => {
            this.$toast.open({
              message: response.data.message,
              type: "success",
              position: "bottom",
              duration: 4000,
              queue: true,
            });

            this.open_photo = false
            this.open_client_modal = false
            this.photo = ''

          })
          .catch(error => {
            console.log(error);
          });
    },
    saveSocial() {
      let data = {
        name: this.name,
        value: this.value,
        company_id: this.company_id
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}guest/company/social`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });

            this.open_social = false
            this.open_client_modal = false
            this.name = ''
            this.value = ''

          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    savePhone() {
      let data = {
        name: this.name,
        value: this.value,
        company_id: this.company_id
      };

      this.$axios({
        method: 'post',
        url: `${this.$API_URL}guest/company/phone`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
        data: data
      })
          .then((res) => {
            console.log(res);
            this.$toast.open({
              message: res.data.message,
              type: "warning",
              position: "bottom",
              duration: 5000,
              queue: true,
            });

            this.open_phone = false
            this.open_client_modal = false
            this.name = ''
            this.value = ''

          })
          .catch((error) => {
            this.$toast.open({
              message: error.response.data.error,
              type: "error",
              position: "bottom",
              duration: 5000,
              queue: true,
            });
          });
    },
    openPhoto() {
      this.open_photo = true
    },
    openSocial() {
      this.open_social = true
    },
    openAddress() {
      this.open_address = true
    },
    subDay(date) {
      const today = new Date();
      const d = new Date(date);

      // Calculate the difference in milliseconds
      const differenceMs = today - d;

      // Convert milliseconds to days
      const differenceDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));

      return differenceDays;
    },
    checkDate(time) {
      const today = new Date();
      const date = new Date(time);
      return date.getDate() === today.getDate()
    },
    formatDate(time) {
      const date = new Date(time);
      return `${date.getDate()}.${date.getMonth() + 1}.${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}`;
    },
    openClientModal(client) {
      this.is_client = client.is_client
      this.email = client.email
      this.name = client.name
      this.open_client_modal = true
      this.company_id = client.id
      this.description = client.description
    },
    getClients(bool) {
      this.show_clients = bool
      this.$axios({
        method: 'get',
        url: `${this.$API_URL}guest/clients?is_client=${bool}`,
      })
      .then((res) => {
        this.clients = res.data
        console.log(this.clients)
      })
    },
    saveIsClient() {
      let data = {
        is_client: this.is_client,
        id: this.company_id,
        description: this.description,
        city: this.city,
        name: this.name
      }

      this.$axios({
        method: 'put',
        url: `${this.$API_URL}guest/clients`,
        data: data
      })
          .then((res) => {
            console.log(res.data)
            this.open_client_modal = false
            this.getClients(!this.is_client)
          })
    }
  }

};
</script>

<style>

.guest__page {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

}

.guest__page--companies {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.guest__page--company {
  margin-bottom: 20px;

  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-right: 20px;
}

.guest__page--info {
  margin-top: 20px;
  width: 95%;
  padding: 20px;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin-bottom: 30px;
}



.qr--btn {
  background: #2a79f0;
  padding: 15px 20px;
  color: white;
  border-radius: 5px;
}


.guest__page--company-block {
  display: flex;
  flex-direction: column;
  width: 90%;
}
.guest__page--title {
  margin-bottom: 10px;
  align-self: flex-start;
}

.guest__page--header {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  width: 90%;
  justify-content: space-between;
}
.guest__page--header i {
  color: #2a79f0;
}

.guest__page--top {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;

  p {
    font-weight: bold;
    cursor: pointer;
  }
}


.title--active {
  color: deepskyblue;
}


.guest__page--photos {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 45px;
  .guest__page--photo {
    display: flex;
    flex-direction: column;
    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
  }
}


.guest__page--phones {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
}

</style>